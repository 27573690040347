/*#395CBE background color => Transaparency change only*/


/*https://codepen.io/jidelambo/pen/RaRygY*/

body {
    padding: 0px !important;
    min-height: auto !important;
}

html {
    position: relative;
    min-height: 100%;
}

.bg-full-image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: relative;
}

.bg-full-image:before {
    background-image: url(../img/header_bg.jpg?v3) !important;
    background-size: cover !important;
    background-attachment: fixed;
    content: '';
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 200vh !important;
}

.fancy-shadow {
    box-shadow: 2px 10px 20px 0 rgb(27 30 35 / 50%);
    border-radius: 10px !important;
}

.break-all {
    word-break: break-all;
}

.secret-container {
    display: flex;
    align-items: center;
}

.footer {
    margin-top: 1rem;
    position: absolute;
    width: 100%;
    /* height: 100%; */
    height: 4px;
    /* line-height: 80px; */
}

.table-responsive>.row {
    flex-wrap: nowrap;
}

.footer-container .footer {
    height: inherit !important;
    line-height: inherit !important;
    margin-bottom: 10px !important;
}

.base-footer .footer {
    position: relative !important;
    margin-top: 3 !important;
}

.comp-name {
    font-weight: bold !important;
    font-size: 14px;
    color: black;
}

.token-container:hover {
    background-color: #EBEBEB;
    border-radius: 10px;
    transform: translateY(-5px);
    transition: 0.6s;
}

.token-text {
    font-size: 14px;
    color: black !important;
    font-weight: bold !important;
}

.amount-n-dropdown {
    display: flex;
}

.custom-wrapper .footer {
    position: relative;
}

.color-white {
    color: white !important;
}

.react-confirm-alert-button-group {
    justify-content: flex-end !important;
}

.react-confirm-alert-button-group button:first-child {
    background-color: #ffffff;
    color: #395cbe;
    border-radius: 8px;
    border: 1px solid #395cbe;
}

.react-confirm-alert-button-group button:last-child {
    background-color: #395cbe;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #395cbe;
}

.bg-overlay {
    /* background: rgba(57, 92, 190, 0.35); */
    background: rgba(63, 81, 181, 0.5) !important;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.all-wrapper.with-pattern {
    background-image: none !important;
    min-height: 80vh !important;
}

.auth-box-w.wider {
    max-width: 100% !important;
    border-radius: 50px !important;
}

.auth-box-w.terms-wider {
    max-width: 90% !important;
}

.min-height-90vh {
    min-height: 90vh !important;
}

.auth-box-w.login-box {
    max-width: 750px !important;
    /* margin-top: 8em; */
    /* margin: 4em 0; */
    border-radius: 50px !important;
}

.auth-box-w .logo-w {
    padding: 20px 0 0 0 !important;
}

.auth-box-w {
    background-color: #395cbe !important;
    opacity: 0.95 !important;
}

.custom-form .form-control {
    padding: 18px !important;
    border-radius: 30px !important;
    border: none !important;
    color: grey !important;
    font-size: 16px !important;
}

.custom-form ::placeholder {
    color: grey !important;
    opacity: 1;
}

.custom-submit-button {
    width: 100% !important;
    border-radius: 50px !important;
    padding: 20px !important;
    background: linear-gradient(134deg, #56b8e4 0%, #6b8ccf 100%) !important;
    border: 1px solid white !important;
}

.auth-box-w .buttons-w {
    border-top: none !important;
}

.info-bottom-link {
    width: 100% !important;
    text-align: end !important;
    color: white !important;
    margin-bottom: 15px !important;
    float: right !important;
    padding-right: 15px !important;
    font-size: 18px !important;
}

.logout-link {
    color: white !important;
    float: right !important;
    padding: 5px 20px !important;
    font-size: 18px !important;
    border: 2px solid white;
    border-radius: 50px;
}

.terms-block {
    font-size: 18px !important;
    color: white !important;
}

.auth-box-w form {
    padding: 20px 40px 40px 40px !important;
}

.type-selector {
    border: 1px solid white !important;
    border-radius: 50px !important;
    padding: 15px !important;
    color: white !important;
}

.copyright-text {
    font-size: 14px !important;
}

.type-selector label {
    padding-right: 15px !important;
    padding-left: 0.5rem !important;
    font-size: 16px;
}

.type-selector span {
    padding-left: 5px !important;
}

.gender-container label {
    padding-right: 10px !important;
    font-size: 16px;
}

.gender-container span {
    padding-left: 5px !important;
}

.main {
    width: 50%;
    margin: 50px auto;
}

.custom-form .input-group-text {
    position: absolute !important;
    z-index: 999 !important;
    top: 15% !important;
    left: 0% !important;
    padding: 14px !important;
    border-radius: 50px !important;
    margin-left: 5px !important;
    background: #3a5ebe !important;
    color: white !important;
}

.custom-form .input-group input {
    text-indent: 2.75rem !important;
}

span.error {
    color: #ccd9e8 !important;
    font-size: 15px !important;
}

.login-container .auth-box-w .buttons-w {
    padding-top: 2rem !important;
}

.custom-form .form-control {
    width: 100% !important;
}

.disabled {
    opacity: 0.9 !important;
    cursor: not-allowed !important;
}

.custom-top-bar {
    background-color: #395cbe !important;
}

.custom-bg-overlay {
    z-index: 0 !important;
}

.top-bar:not(.admin-top-bar) .top-menu-controls {
    padding: 25px 0 !important;
}

.customLogo {
    margin-left: 15px !important;
}

.custom-username {
    color: white !important;
    font-size: 16px;
}

.custom-wrapper {
    height: 100% !important;
    min-height: 100vh !important;
    min-width: 349px !important;
}

.custom-background-color {
    background-color: #fafafa !important;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 20%);
    height: auto !important;
    min-height: 100vh !important;
}

.user-dashboard-header-bg-color {
    background-color: #5e9ed8 !important;
    height: auto !important;
    min-height: auto !important;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.5) !important;
}

.custom-element-box {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    /* margin: 0px 30px; */
}

.custom-element-box .element-box {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 0 !important;
}

.btn-colored {
    background-color: #395cbe;
    border-radius: 8px;
    color: white;
}

.btn-colored:hover {
    color: white;
}

.token {
    margin: 12px 0px;
}

.card-max-height {
    display: block;
    height: 98%;
}

.custom-element-box .form-header {
    color: #333333 !important;
    font-size: 20px;
}

.kyc-container .form-control:not(.no-border-radius) {
    background-color: transparent !important;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    color: #333333 !important;
}

.kyc-container ::placeholder {
    color: white;
    opacity: 0.5;
}

.permian-button {
    padding: 15px 60px;
    border-radius: 50px;
    background-color: #395cbe !important;
}

.padding-fix {
    padding: 0 30px;
}

.permian-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}

.kyc-container select {
    color: white !important;
}

.kyc-container .dropzone {
    background-color: #395cbe !important;
    border: 2px dashed white !important;
}

.resource-continaer {
    background-color: #395cbe !important;
}

.resource-continaer.dropzoneIdContainer {
    padding: 20px !important;
}

.payment-proof-container {
    /* border: 2px dotted #ebebeb !important; */
    background-color: transparent !important;
}

.payment-proof-container.dropzoneIdContainer {
    padding: 2px !important;
}

.payment-proof-container label,
.payment-proof-container div {
    color: #395cbe !important;
}

.payment-proof-container .filepond--root {
    margin-bottom: 0px !important;
}

.filepond--root .filepond--drop-label {
    min-height: 3.75em !important;
}

.font-400 {
    font-weight: 400;
}

.country-select2 .cntry__control {
    background-color: transparent !important;
    border-radius: 8px !important;
    color: #395cbe;
}

.country-select2 ::placeholder,
.cntry__single-value,
.cntry__placeholder,
.cntry__indicators svg {
    color: #395cbe !important;
}

.cntry__menu {
    background-color: #5e9ed8 !important;
    color: white !important;
}

.cntry__option--is-focused {
    background-color: #395cbe !important;
}

.custom-content-container {
    overflow: hidden !important;
}

.dob-container .react-datepicker-wrapper,
.dob-container .react-datepicker__input-container {
    display: inline !important;
}

.kyc-agree-container {
    /* margin-top: 25px !important; */
    padding-left: 0px !important;
}

.kyc-agree-container label {
    font-size: 14px !important;
}

.progress-steps-container .rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: white;
}

.dropzoneIdContainer {
    padding: 10px 0 !important;
}

.rdtPicker {
    background-color: #5e9ed8 !important;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #5e9ed8 !important;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #5e9ed8 !important;
}

.rdtPicker thead tr:first-child th:hover {
    background: #5e9ed8 !important;
}

td.rdtMonth:hover,
td.rdtYear:hover {
    background: #5e9ed8 !important;
}

.cntry__input {
    color: white !important;
}

.kyc-status-container {
    color: white !important;
}

.progress-icon-container {
    padding: 12px !important;
    background-color: #395cbe !important;
    border-radius: 50%;
}

.kycstatus-head {
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 24px;
}

body.menu-position-side .content-w {
    border-radius: 0 !important;
}

.required-sign {
    color: red !important;
}

.admin-container,
.admin-container .menu-w,
.user-dashboard-container,
.user-dashboard-container .menu-w {
    height: 100% !important;
    min-height: 100vh !important;
    min-width: 16% !important;
}

.progress-icon-container.bg-red {
    background-color: red !important;
}

.kyc-status-button {
    padding: 0px 24px !important;
    background: #ffffff;
    border: 1px solid #395cbe;
    border-radius: 8px;
    height: 32px;
    color: #395cbe;
}

.kyc-content {
    margin-top: 4rem;
    height: 60vh;
}

.text-bold {
    font-weight: bolder !important;
}

.buy-xpr-container {
    font-size: 16px !important;
}

.dark-blue-theme-color {
    border-radius: 8px;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
}

.dark-blue-font-color {
    color: #395cbe !important;
}

.buy-xpr-header-block {
    font-size: 16px !important;
    color: #333333;
}

.buy-xpr-input {
    width: 70% !important;
    height: 30px !important;
    border-radius: 8px 0px 0px 8px;
    opacity: 1 !important;
    border: 1px solid #ebebeb !important;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    padding-right: 10px;
}

.buy-xpr-input:focus {
    outline: none;
}

.card-padding {
    padding: 22px 30px;
}

.buy-xpr-currencey-select {
    height: 30px !important;
    border-radius: 0px 8px 8px 0px;
    background-color: #ffffff;
    border: 1px solid #ebebeb !important;
    padding: 0 2px !important;
    font-size: 16px;
    width: 30%;
    text-align: center;
}

.buy-xpr-currencey-select:focus {
    outline: none;
}

.buy-xpr-currencey-select option {
    background-color: #59adde !important;
}

.token-symbol-input:focus {
    outline: none;
}

.buy-xpr-currencey-select,
.buy-xpr-currencey-select option {
    color: #395cbe !important;
}

.tokens-xpr-input {
    width: 70% !important;
    padding-left: 3px !important;
    height: 30px !important;
    border-radius: 8px 0px 0px 8px;
    opacity: 1 !important;
    border: 1px solid #ebebeb !important;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
}

.tokens-xpr-input:focus {
    outline: none;
}

.token-symbol-input {
    padding: 0 10px !important;
    width: 30% !important;
    background-color: #ffffff;
    border: 1px solid #ebebeb !important;
    opacity: 1 !important;
    border-radius: 0px 8px 8px 0px;
    height: 30px !important;
    font-size: 16px;
    color: #395cbe;
    text-align: center;
}

.highligher {
    font-size: 16px;
    font-weight: 600;
}

.receive-text {
    font-size: 16px;
}

.eth-contribution-input {
    width: 55% !important;
    border-radius: 8px !important;
    height: 30px !important;
    border: white !important;
    opacity: 1;
    background-color: #ebebeb !important;
    padding-left: 10px !important;
}

.eth-contribution-input:focus {
    outline: none;
}

.buy-xpr-container h3,
.buy-xpr-container h6 {
    font-weight: normal !important;
}

.buy-xpr-button {
    border-radius: 8px !important;
    margin-left: 20px !important;
    padding: 5px 15px !important;
    font-size: 16px;
    background-color: #ffffff;
}

.qrcode-image-container img {
    margin-top: 15% !important;
}

.buy-online-payment-buttons {
    width: 15%;
    background-color: transparent;
}

.admin-error-msg,
.validation-red-error {
    color: red !important;
}

.instructon-modal-container {
    padding: 0 !important;
    min-width: 30%;
    width: 40%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.instructon-modal-container .onboarding-modal .onboarding-content .onboarding-text {
    color: black !important;
}

.required-field {
    color: red;
}

.my-account-container .my-account-header-block h5 {
    font-weight: 400 !important;
}

.account-button {
    padding: 0px 12px;
    border-radius: 8px;
    background-color: #ffffff !important;
    border: 1px solid #395cbe !important;
    color: #395cbe;
    margin: 10px 0px;
    height: 32px;
}

.btn-large {
    height: 32px;
}

.btn-small {
    height: 24px;
}

.account-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}

.my-account-button {
    margin: 10px 0 !important;
    padding: 10px 40px !important;
}

.my-account-container .kyc-success-icon-container {
    padding: 12px !important;
    background-color: white !important;
    color: #395cbe !important;
    border-radius: 50%;
}

.my-account-container .verified-text {
    font-size: 1.3rem !important;
}

.my-account-container h5 span {
    font-size: 20px;
}

.buy-currency-box-display {
    padding: 2.5rem !important;
    border-radius: 50px !important;
    background-color: grey !important;
}

.withdraw-token-btn {
    width: 100px;
    height: 35px;
}

.buy-copy-button {
    padding: 15px;
    border-radius: 50px;
    background-color: #395cbe !important;
    border-color: white !important;
    margin-left: 15px !important;
}

.filter-blur {
    filter: blur(3px);
}

.modal-contribution-address {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.my-account-header-block {
    overflow: auto;
    min-height: 240px !important;
}

.two-fa-code-input {
    width: 250px !important;
    display: inline-block !important;
    margin: 10px 15px !important;
}

.two-factor-heading-text {
    font-size: 16px !important;
}

.converter-sign-container {
    text-align: center;
}

.receiving-eth-address {
    border-radius: 8px !important;
    border: 1px solid #ebebeb !important;
    color: #333333 !important;
    font-size: 12px !important;
    line-height: 18px;
    font-weight: normal;
}

.myaccount-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.kyc-file-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: 8rem !important;
    border: 1px white dashed;
}

.kyc-multifile-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: auto !important;
    border: 1px white dashed;
    min-height: 8rem !important;
}

.upload-text-display {
    display: table-cell !important;
    vertical-align: middle !important;
}

.width-auto {
    width: auto !important;
    display: inline !important;
}

.display-inline {
    display: inline !important;
}

.onboarding-modal .onboarding-slide .onboarding-content:not(.tokenpayment-content-modal) {
    padding: 24px !important;
}

.modal-button {
    height: 32px;
    padding: 0px 24px;
    border-radius: 8px;
}

.cancel-button {
    background-color: #ffffff;
    color: #395cbe;
    border: 1px solid #395cbe;
}

.update-button {
    background-color: #395cbe;
    color: #ffffff;
    border: 1px solid #395cbe;
    margin: 0px 12px;
}

.progressbar {
    counter-reset: step !important;
}

.progressbar li {
    list-style: none !important;
    display: inline-block !important;
    width: 20% !important;
    position: relative !important;
    text-align: center !important;
    font-size: 14px;
}

.progressbar li:before {
    content: counter(step) !important;
    counter-increment: step !important;
    width: 24px !important;
    height: 24px !important;
    border: 1px solid #395cbe !important;
    border-radius: 100% !important;
    display: block !important;
    text-align: center !important;
    margin: 0 auto 5px auto !important;
    background-color: #fff !important;
    color: #395cbe;
    line-height: 22px;
}

.progressbar li:after {
    content: '' !important;
    position: absolute !important;
    width: 100% !important;
    height: 1px !important;
    background-color: #395cbe !important;
    top: 10px !important;
    left: -50% !important;
    z-index: 0 !important;
}

.kyc-header {
    font-size: 20px;
    line-height: 24px;
    color: #395cbe;
    margin: 30px 0;
}

.kycstatus-description {
    margin: 12px;
    font-weight: normal;
}

.kycstatus-heading {
    margin: 12px 0px;
    font-weight: normal;
}

.progressbar li:first-child:after {
    content: none !important;
}

.progressbar li.active {
    color: #333333 !important;
}

.progressbar li.active:before {
    border-color: white !important;
    color: white !important;
    background-color: #395cbe !important;
}

.progressbar li.active+li:after {
    background-color: #395cbe !important;
    z-index: 1 !important;
}

.filter-element-wrapper {
    padding-bottom: 0rem !important;
}

.filter-element-wrapper .form-control {
    width: 20rem !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.dashboard-header {
    font-size: 14px !important;
    margin: 6px 0px;
}

.color-red {
    color: red !important;
}

.dashboard-statistics-container:not(:last-child),
.user-statistics-container {
    border-right: 1px solid white !important;
}

.barrel-display {
    justify-content: center !important;
    display: inline-flex !important;
    padding: 6px 12px !important;
    color: #333333;
    background: #ebebeb;
    border-radius: 8px;
    margin: 6px 0px;
    font-size: 14px;
}

.element-wrapper.compact {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 5%);
    border-radius: 8px !important;
}

.element-box {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.bg-white {
    background: white !important;
}

.dashboard-my-investment-list td {
    padding: 12px !important;
    font-size: 14px;
    color: #333333;
}

.investment-card-title {
    font-size: 14px;
    color: #333333;
    margin: 6px 0px;
}

.card-inner-padding {
    padding: 24px;
}

.card-horizontal-padding {
    padding: 0 24px;
}

.investment-card-value {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    background-color: #ebebeb;
    border-radius: 8px;
    padding: 6px 12px;
    display: inline-block;
}

.dashboard-my-investment-list.table thead th {
    border-bottom: none !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-small {
    font-size: 12px;
}

.text-default {
    font-size: 14px;
}

.text-large {
    font-size: 16px;
}

.text-xl {
    font-size: 20px;
}

.text-default-color {
    color: #333333;
}

.width-12 {
    width: 12%;
}

.default-border-btn {
    background-color: #ffffff;
    color: #395cbe;
    border: 1px solid #395cbe;
}

.custom-tooltip {
    /* display: flex; */
    position: relative;
}

.twofa-submit-button {
    background: #ffffff;
    border: 1px solid #395cbe;
    border-radius: 8px;
    color: #395cbe;
}

.custom-tooltip:hover:after {
    background-color: #ebebeb;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    top: 26px;
    color: #333333;
    content: attr(tooltip-title);
    left: 0;
    font-size: 12px;
    text-align: left;
    line-height: 16px;
    padding: 15px 20px;
    position: absolute;
    z-index: 98;
}

.emd-selected {
    border: 1px solid #395cbe;
}

.default-tb-margin {
    margin: 12px 0px;
}

.default-lr-margin {
    margin: 0px 12px;
}

.css-dvua67-singleValue {
    color: #395cbe !important;
    font-size: 12px;
}

.css-16pqwjk-indicatorContainer {
    color: #395cbe !important;
}

.custom-tooltip:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    top: 20px;
    content: '';
    left: 50%;
    position: absolute;
    z-index: 99;
}

.custom-tooltip.tooltip-right:hover:after {
    right: 20% !important;
    left: inherit !important;
}

.custom-tooltip.tooltip-right:hover:before {
    right: 50% !important;
    left: inherit !important;
}

.display-inherit {
    display: inherit !important;
}

.type-radio-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.white-background {
    background-color: white !important;
}

.xpr-chart-header {
    color: #333333 !important;
    font-size: 20px !important;
}

.qrcode-image-container img {
    padding-bottom: 20px !important;
}

.document-type-header {
    color: #395cbe !important;
}

.filepond--root {
    min-height: 5rem !important;
    border: 2px dotted #ebebeb !important;
    margin-bottom: 0 !important;
}

.filepond--panel-root {
    background-color: transparent !important;
}

.filepond--drop-label {
    color: #395cbe !important;
    top: 20% !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.filepond--file-action-button.filepond--action-process-item {
    display: none !important;
}

.split-radio-container label {
    min-width: 35% !important;
}

.understanding2-split-radio-container label {
    min-width: 33% !important;
}

.wealth-split-radio-container label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.understanding-split-radio-container label {
    min-width: 20% !important;
    margin-top: 5px !important;
}

.radio-half-width-container label {
    min-width: 50% !important;
    margin-top: 5px !important;
}

.financial-products-invested label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.financial-information-textarea {
    background-color: transparent !important;
    color: white !important;
    resize: none !important;
}

.overflow-wrap {
    overflow-wrap: break-word;
}

.signature-pad-container {
    border: 2px dotted #ebebeb !important;
}

.clear-sign-action {
    font-size: 14px !important;
    cursor: pointer;
}

.terms-container {
    padding: 40px !important;
    color: white !important;
    text-align: justify;
}

.cntry__menu-list {
    max-height: 10rem !important;
}

.identification-data-container,
.identification-data-container h3 {
    font-size: 16px !important;
}

.identification-data-container .row {
    margin-bottom: 10px !important;
}

.menu_active {
    background-color: #ebebeb;
}

.signature-container-img {
    max-width: 100% !important;
    width: 100% !important;
}

.user-dashboard-content {
    overflow: auto;
    background-color: #fafafa !important;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

.user-dashboard-menu {
    background-color: white !important;
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    z-index: 99 !important;
    top: 91px !important;
}

.user-dashboard-menu li span {
    color: #395cbe !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    opacity: 1 !important;
}

.header-fixed-menu {
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    z-index: 100 !important;
}

.center-content {
    padding-top: 91px !important;
}

.user-dashboard-content {
    position: relative !important;
}

.color-black {
    color: #333333;
}

.alert-dark-blue {
    color: black !important;
    font-size: 16px !important;
    border: none;
    border-radius: 8px;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
}

.btn-outline {
    border: 1px solid #395cbe;
    border-radius: 8px;
    margin: 0px 12px;
    color: #395cbe;
}

.menu-w ul.sub-menu li a {
    padding-left: 55px;
}

.menu-w ul.sub-menu {
    padding: 0px;
    padding-left: 0px !important;
}

.selected:hover,
.cusror-pointer:hover,
.sub-menu li:hover {
    background: #ebebeb;
}

.worth-highlighter {
    font-size: 14px !important;
    color: #333333 !important;
    margin: 6px 0px;
}

.user-dashboard-content {
    min-height: 85vh !important;
}

.content-box {
    padding-bottom: 0 !important;
}

.progressbar li:after {
    width: calc(100% - 24px) !important;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #395cbe;
}

.buyNrtNotes {
    padding: 24px !important;
}

.buyNrtNotes ol {
    padding-inline-start: 15px;
}

.instructon-modal-container ol {
    counter-reset: item;
}

.instructon-modal-container li {
    display: block;
}

.instructon-modal-container li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
}

.clearNumber {
    counter-reset: list !important;
}

.clearNumber .incrementNo li {
    list-style: none !important;
    position: relative !important;
}

.clearNumber li::before {
    counter-increment: list !important;
    content: '(' counter(list, lower-alpha) ') ' !important;
}

.clearAlpha {
    counter-reset: list !important;
}

.clearAlpha li {
    list-style: none !important;
    position: relative !important;
}

.clearAlpha li::before {
    counter-increment: list !important;
    content: '(' counter(list, lower-roman) ') ' !important;
}

.clearNumbers {
    counter-reset: number !important;
}

.clearNumbers li::before {
    counter-increment: number !important;
    content: ' ' counter(number) '. ' !important;
}

.underline {
    text-decoration: underline !important;
}

.instructionCheck {
    margin-left: 1rem !important;
    display: inline !important;
    margin-bottom: 1rem !important;
}

.declarationCheck {
    float: left !important;
}

.declarationLabel {
    display: block !important;
    margin-left: 40px !important;
}

.my-account-error-msg {
    font-size: 16px !important;
    border: 1px solid red;
}

.my-account-error-msg a {
    color: white !important;
    text-decoration: underline !important;
}

.myaccount-error-icon {
    color: red !important;
    font-size: 20px !important;
}

.assumption-block {
    color: black !important;
}

.overflow-auto {
    overflow: auto;
}

.w-350 {
    width: 350px;
}

.portfolio-page-container {
    overflow: hidden;
}

.exchange-currnecy {
    color: white;
    background-color: transparent;
    border-color: white;
    border-radius: 50px;
    padding-left: 10px;
    margin-left: 10px;
}

.exchange-btn {
    background: transparent !important;
    border-color: white !important;
    border-radius: 50px !important;
    padding: 4px 45px !important;
    color: white !important;
    border: 1px solid white !important;
}

.cursor-pointer {
    cursor: pointer;
}

.exchange-input {
    margin-left: 10px !important;
    padding-left: 15px !important;
    height: 30px !important;
    border-radius: 50px !important;
    opacity: 1 !important;
    border: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: right !important;
    padding-right: 10px !important;
    width: 70% !important;
    text-align: left !important;
}

.exchange-xpr-label {
    font-size: 13px !important;
}

.exchange-input:focus,
.exchange-input:focus,
.exchange-currnecy:focus,
.exchange-input2:focus,
.exchange-input2:focus,
.exchange-btn2:focus {
    outline: none !important;
}

.xpr-receive-text {
    font-size: 11px !important;
}

.exchange-dashboard-content {
    padding-top: 91px;
    min-height: 100vh !important;
    background: rgb(47, 61, 69);
}

.mandate-book-container {
    padding: 1rem !important;
}

.sell-text {
    color: rgb(249, 103, 45) !important;
}

.buy-text {
    color: green !important;
}

.mandate-header {
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.exchange-header-block {
    font-size: 14px !important;
    background: rgb(21, 35, 44) !important;
}

.pending-text {
    color: #bdbd24 !important;
}

.accepted-text,
.executed-text {
    color: green !important;
}

.rejected-text {
    color: red;
}

.border-right-seperator {
    border-right: 1px solid white;
}

.exchange-input2 {
    margin-left: 5px !important;
    padding-left: 10px !important;
    height: 22px !important;
    border-radius: 10px;
    opacity: 1 !important;
    border: white !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: right !important;
    padding-right: 10px !important;
    width: 100% !important;
    text-align: left !important;
}

.exchange-label {
    font-size: 11px !important;
}

.btn-primary:hover,
.all-wrapper .fc-button.fc-state-active:hover {
    color: #ffffff !important;
    background-color: #0368d2;
    border-color: #0362c6;
}

.exchange-btn2 {
    background: transparent !important;
    border-color: white !important;
    border-radius: 50px !important;
    padding: 3px 18px !important;
    color: white !important;
    border: 1px solid white !important;
    font-size: 11px !important;
}

.exchange-currnecy option {
    background: rgb(47, 61, 69) !important;
    color: #fff;
}

.content-panel.color-scheme-dark {
    background: rgb(21, 35, 44) !important;
}

.exchange-top-bar {
    background-color: rgb(25, 33, 38) !important;
}

.exchange-base-footer {
    margin-top: 25px;
    padding: 0px 0px;
    font-size: 7px;
    background: #192126;
    position: absolute !important;
    width: 100% !important;
    bottom: 0 !important;
}

.exchange-footer {
    height: 30px;
    line-height: 30px;
    padding: inherit;
    font-size: 14px !important;
}

.permian-exchange-container .table.table-compact td,
.permian-exchange-container .table th {
    padding-left: 0px !important;
}

.unit-price-seperator {
    color: grey !important;
}

#recent-order-container {
    min-height: 142px;
}

.subscribe-modal-content {
    /* max-height: calc(100vh - 180px); */
    overflow-y: auto;
}

.account-radio-container label {
    min-width: 12% !important;
}

.notification-text {
    font-size: 16px !important;
}

.exchange-usd-submit-btn {
    padding: 7px 20px !important;
}

.exchange-wallet-copy-address {
    padding: 5px 15px !important;
    margin-left: 10px !important;
    border: none !important;
}

.exchange-wallet-copy-address:focus {
    box-shadow: none !important;
    border: none !important;
}

.exchange-currency-modal-container .modal-dialog {
    max-width: 800px !important;
    padding: 0 !important;
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.75) !important;
}

.mandate-book-container input[type='number']::-webkit-outer-spin-button,
.mandate-book-container input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
}

.os-dropdown-trigger.os-dropdown-position-left:hover .os-dropdown {
    -webkit-transform: translate3d(0%, 100%, 0) !important;
    transform: translate3d(0%, 100%, 0) !important;
}

.os-dropdown-trigger:hover .os-dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0);
}

body.page-loaded .css3-spinner {
    display: none !important;
}

.exchange-content-container {
    padding: 0 !important;
}

.sell-text,
.buy-text {
    font-size: 14px !important;
    font-weight: 150 !important;
}

.top-bar .top-icon i {
    color: white !important;
}

.menu-vertical-align-set {
    vertical-align: inherit !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.metamaskbutton {
    position: relative;
    height: 60px;
    min-width: 200px;
    background: none;
    border: none;
    color: white;
    border-radius: 60px;
    padding: 0 25px 0 40px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient( to right, #f7861c 0%, rgba(0, 0, 0, 0) 100%);
    background-color: #c86dd7;
    cursor: pointer;
    line-height: 20px;
    font-size: 18px;
    transition: 400ms all;
    outline: 0 !important;
}

.metamaskbutton:hover {
    background-color: #8f68e4;
}

.metamask-payment-container .logo {
    top: 8px;
    left: -10px;
    width: 42px;
    height: 42px;
    position: absolute;
    /* z-index: 1; */
}

.metamask-payment-container .text {
    font-weight: 600;
}

.metamask-payment-container .withMetamask {
    margin-left: 6px;
    opacity: 0.7;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
}

.metamask-payment-container .amounts {
    font-weight: 600;
}

.metamask-payment-container .amountBig {
    font-size: 20px;
}

.metamask-payment-container .amountSmall {
    font-size: 16px;
}

.metamask-payment-container .conversion {
    opacity: 0.7;
    font-weight: 400;
}

.metamask-payment-container .approx {
    position: relative;
    top: -1px;
    margin: 0 5px;
    font-size: 14px;
}

.metamask-payment-container .status {
    font-size: 15px;
}

.metamask-payment-container img.symbolEthLogo {
    width: 15px;
}

.metamask-payment-container .symbolEthLogo {
    position: relative;
    top: -1px;
    margin-left: 2px;
    display: none;
}

.metamask-payment-container .symbolEth {
    margin-left: 5px;
}

.token-payment-modal .cntry__single-value,
.token-payment-modal .cntry__placeholder,
.token-payment-modal .cntry__indicators svg {
    color: black !important;
}

.token-payment-modal-container {
    min-width: 500px !important;
}

.display-block {
    display: block !important;
}

.ticker-widget-container {
    overflow: hidden !important;
}

.token-address-text {
    font-size: 16px !important;
    font-weight: bolder !important;
}

.cryptounitpricetext {
    color: white !important;
    font-size: 14px !important;
}

.tokenpayment-content-modal {
    padding: 40px 20px !important;
}

.normalfontweight {
    font-weight: normal !important;
}

.subscribe-pay-request-submit,
.custom-button-padding {
    padding: 6px 30px !important;
}

.my-balance-element-wrapper {
    padding-bottom: 1.5rem !important;
}

.currency-type-select2 .cntry__control,
.currency-type-select2 .cntry__single-value {
    color: #495057 !important;
}

#chaparral_ticker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem !important;
    border-radius: 5px !important;
}

.c-ticker {
    background-color: rgba(0, 0, 0, 0.7);
}

#chaparral_ticker .stock_ticker_data {
    right: 0xp !important;
    width: 180px;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #fff;
    font-weight: normal;
    font-size: 14px !important;
    text-align: left;
    box-shadow: 0 1px 2px #fff, /*bottom external highlight*/
    0 -1px 1px #666, /*top external shadow*/
    inset 0 -1px 1px rgba(0, 0, 0, 0.5), /*bottom internal shadow*/
    inset 0 1px 1px rgba(255, 255, 255, 0.8);
    /*top internal highlight*/
    border-radius: 5px !important;
}

.c-ticker .chs_exchange {
    margin-bottom: -0.4em;
    padding-left: 15px;
    color: #999;
}

.c-ticker .chs_symbol {
    padding-left: 15px;
    font-size: 18px;
}

.c-ticker .chs_symbol,
.c-ticker .chs_pricing {
    display: inline-block;
}

#chaparral_ticker .stock_ticker_data {
    right: 0xp !important;
    width: 180px;
    padding-top: 10px;
    color: #fff;
    font-weight: normal;
    font-size: 14px !important;
    text-align: left;
}

.c-ticker .chs_pricing {
    font-size: 18px;
}

.c-ticker .chs_symbol,
.c-ticker .chs_pricing {
    display: inline-block;
}

#chaparral_ticker .stock_ticker_data .chs_pricing .ticker__green {
    color: #9f0 !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.react-confirm-alert-body>h1 {
    font-size: 20px !important;
    line-height: 20px;
    margin: 12px 0px;
}


/* .centerofscreen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */


/* .auth-box-w.centerofscreen {
  min-width: 40% !important;
}
 */

.auth-box-w.signupwidth {
    margin: 2em 0;
    min-width: 50% !important;
}

.footer-container {
    clear: both;
    /* position: relative !important; */
}

.form-check {
    padding-left: 0.25rem !important;
}

.text-underline {
    text-decoration: underline !important;
}

.basepage-footer-container {
    position: fixed !important;
    bottom: 5px !important;
    left: 0px !important;
    right: 0px !important;
    margin-bottom: 0px !important;
}

.header-border {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
    left: 10px;
}


/* .header-border:after {
  content: "";
  background-color: #047bf8;
  width: 25px;
  height: 4px;
  border-radius: 0px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0px;
} */

.width-35 {
    width: 35% !important;
}

.width-50 {
    width: 50% !important;
}


/* .width-100 {
  width: 100% !important;
} */

.full-width {
    width: 100%;
}

.signuppt {
    padding-top: 2.5rem !important;
}

.signinpt {
    padding-top: 3.5rem !important;
}

.signup-button {
    margin-bottom: 0rem !important;
}

.content-box {
    min-height: 78.6vh !important;
}

.transactioncontainer .tabs {
    margin: 0 auto;
    width: 100%;
    margin-top: 2vh;
}

.transactioncontainer img {
    width: 300px;
}

.transactioncontainer .tab-buttons {
    margin-bottom: 25px;
    border-bottom: 2px solid #59adde;
}

.transactioncontainer button:not(.dropdown-toggle) {
    margin-right: 15px;
    font-size: 20px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 20px 10px 20px;
    cursor: pointer;
    color: #395cbe !important;
    transition: all ease-in-out 0.2s;
    border-bottom: 2px solid transparent;
}

.transactioncontainer button.active {
    border-bottom: 2px solid #59adde;
    color: black;
}

.sendtokennote {
    display: inline-block !important;
    color: red !important;
    font-size: 10px !important;
    font-weight: bold !important;
}

.campaignmaps {
    height: 100vh !important;
}

.top3 {
    top: 3px !important;
}

.listview-campaigns {
    position: absolute;
    top: 10px;
    height: auto;
    z-index: 1;
    right: 70px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    color: white;
}

.emd-list-container .post-box:hover {
    transform: none !important;
    -webkit-transform: none !important;
}

.investor-emd-box {
    height: 90% !important;
    margin-bottom: 20px !important;
}

.investor-dealer-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-w .logged-user-menu.color-style-bright {
    background-color: #395cbe;
}

.menu-w .logged-user-menu .logged-user-avatar-info {
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.menu-w.menu-layout-compact .logged-user-w.avatar-inline .logged-user-i .logged-user-info-w.emdcontainer {
    padding-left: 5px !important;
}

.menu-w .logged-user-menu ul {
    padding: 0px 15px !important;
    padding-bottom: 10px !important;
}

.padding-initial {
    padding: initial !important;
}

.submenuleftpadding {
    padding-left: 1.4rem !important;
}

.menu-padding-set {
    padding-left: 1.5rem !important;
}

.display-block {
    display: block !important;
}

.token-picker-widget {
    padding: 0 2rem 0 0 !important;
}

.padding-10 {
    padding: 10px !important;
}

.fancy-selector-w .fancy-selector-current {
    background-color: #395cbe !important;
}

.fancy-selector-w .fancy-selector-option,
.fancy-selector-w .fancy-selector-options {
    background-color: white !important;
}

.fancy-selector-w .fancy-selector-current .fs-selector-trigger,
.fancy-selector-w .fancy-selector-option .fs-selector-trigger {
    color: white !important;
    padding: inherit !important;
    background-color: transparent !important;
    margin-left: 20px !important;
    margin-right: 10px !important;
    font-size: 18px !important;
    width: inherit !important;
}

.fancy-selector-w .fancy-selector-current .fs-main-info {
    padding: 10px 0px !important;
}

.fancy-selector-w .fancy-selector-current .fs-main-info .fs-name,
.fancy-selector-w .fancy-selector-option .fs-main-info .fs-name {
    font-size: 0.79rem !important;
}

.fancy-selector-w .fancy-selector-options {
    padding: 10px 0 5px 0px !important;
    width: 405px !important;
    top: 90px;
    left: -145px;
}

.fancy-selector-w .fancy-selector-current .fs-main-info .fs-name strong,
.fancy-selector-w .fancy-selector-current .fs-main-info .fs-sub,
.fancy-selector-w .fancy-selector-current .fs-main-info .fs-sub strong {
    color: white !important;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option.active,
.fancy-selector-w .fancy-selector-option .fs-main-info .fs-sub,
.fancy-selector-w .fancy-selector-option .fs-main-info .fs-sub strong,
.fancy-selector-w .fancy-selector-options .fancy-selector-option:hover,
.fancy-selector-w .fancy-selector-options .fancy-selector-option {
    color: #395cbe !important;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}


/* .wallet-statistics-element-wrapper {
  padding-bottom: 1rem !important;
} */

.logged-user-w .logged-user-role {
    color: black;
    font-weight: bold !important;
}

.emd-name-display {
    font-size: 11px !important;
    text-transform: inherit !important;
}

.amcharts-g2 {
    stroke-dasharray: 3px 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -31px;
    }
}

@keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -31px;
    }
}

.amcharts-graph-column-front {
    -webkit-transition: all 0.3s 0.3s ease-out;
    transition: all 0.3s 0.3s ease-out;
}

.amcharts-graph-column-front:hover {
    fill: #496375;
    stroke: #496375;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.amcharts-g3 {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 500%;
    -webkit-animation: am-draw 40s;
    animation: am-draw 40s;
}

@-webkit-keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

@keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

.disclaimer-text {
    margin: 30px 0 15px 15px;
    color: black;
    font-size: 16px;
}

.font-size-12 {
    font-size: 12px !important;
}

.full-width-container {
    max-width: 100% !important;
}

.centerofthescreen {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 1 !important;
    min-height: 90vh !important;
    /* height: 95vh; */
}

.kyc-button-group {
    display: flex;
    flex-direction: row;
}

.kyc-stepper-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;
}

.copyright-text {
    padding: 10px 0px;
}

.kyc-button-group label:not(:first-child) {
    margin-left: 16px;
}

.menu-position-side.menu-w {
    border-right: none;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 5%);
    /* border-radius: 6px 0px 0px 6px; */
    width: 18%;
}

.form-check {
    padding-left: 10px !important;
}

.min-height-90vh {
    min-height: 90vh !important;
}

.min-width-50 {
    min-width: 45% !important;
}

.dealer-image {
    height: 150px !important;
    width: 150px !important;
    /* min-height: 150px !important; */
    background-size: contain !important;
    /* background-position: center center; */
    background-repeat: no-repeat !important;
}

.select-emd-button {
    padding: 2px 8px !important;
}

.permian-button {
    display: flex;
    width: 12rem;
    height: 40px;
    padding: 15px 60px;
    border-radius: 50px;
    background-color: #395cbe !important;
    align-items: center;
    justify-content: center;
}

.token-input-container {
    display: flex;
}

.converter-container {
    display: flex;
    white-space: nowrap;
}

.button-center {
    display: flex;
    justify-content: center;
}

.post-box:hover {
    transform: inherit !important;
}

.post-box .username {
    margin: 0px 20px;
}

.post-box .description {
    margin: 18px 0px;
}

.post-title {
    line-height: 20px;
}

.emd-box1 {
    background: var(--background-color-six);
    height: 90% !important;
    margin-bottom: 20px !important;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);
}

.emd-box1:hover {
    box-shadow: 0px 0px 16px rgb(0 0 0 / 40%);
}

.signuppage-info-bottom-link {
    color: white !important;
    font-size: 18px !important;
}

.signin-info-bottom-link {
    text-align: end !important;
    color: white !important;
    font-size: 18px !important;
}

.menu-w.sub-menu-style-over .sub-menu-w>ul>li>a,
.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i>ul>li>a {
    padding: 5px 40px !important;
    color: #395cbe !important;
}

.kyc-agree-container {
    margin-top: 12px !important;
    display: flex;
    padding-left: 0px !important;
    margin: 12px 0px;
}

.kyc-btn-group {
    margin: 12px 0px;
}

.icheckbox_square-blue {
    margin-left: 5px;
    border-radius: 4px;
}

.menu-w ul.sub-menu li a:hover {
    text-decoration: inherit !important;
    transform: inherit !important;
}

@media (min-width: 992px) and (max-width: 1321px) {
    .secret-container {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .secret-container>span:first-of-type {
        margin-bottom: 10px;
    }
    .secret-container>span:last-of-type {
        position: relative;
        left: -20px;
    }
}

@media (max-width: 1150px) {
    .content-panel {
        visibility: visible !important;
    }
}

@media (max-width: 890px) {
    .secret-container {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .secret-container>span:first-of-type {
        margin-bottom: 10px;
    }
    .secret-container>span:last-of-type {
        position: relative;
        left: -20px;
    }
}

@media (max-width: 767px) {
    .buy-online-payment-buttons {
        width: 30%;
    }
    .table-overflow {
        overflow: auto;
    }
    .menu-container {
        background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 300%);
        background-repeat: repeat-x;
        color: rgba(255, 255, 255, 0.9);
    }
    .mm-logo-buttons-w {
        display: block !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 0 !important;
    }
}

@media (min-width: 992px) {
    .user-dashboard-content {
        margin-left: 18% !important;
    }
    .mgtp-lg-4 {
        margin-top: 1.5rem;
    }
}

.menu-mobile.color-scheme-dark {
    background-color: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    color: #333333;
}

.account-type {
    float: right;
}

@media screen and (min-width: 170px) and (max-width: 575px) {
    .confirmPasswordContainer {
        margin-top: 1.2em;
    }
    .account-type {
        float: left !important;
    }
    .dashboard-statistics-container:not(:last-child),
    .user-statistics-container {
        border-right: none !important;
    }
    .kyc-button-group {
        display: flex;
        flex-direction: column;
    }
    .kyc-button-group label {
        margin: 5px !important;
    }
    .kyc-stepper-btn-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btn-margin-10 {
        margin: 10px;
    }
    .width-200 {
        width: 200px;
    }
    .post-box .username {
        margin: 0px 30px;
        width: 125px;
        overflow-wrap: break-word;
    }
}

@media screen and (max-width: 767px) {
    .mgtp-sm-4 {
        margin-top: 1.5rem;
    }
    .mgtp-sm-3 {
        margin-top: 1rem;
    }
    .converter-sign-container {
        margin: 10px 0;
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    .buy-xpr-header-block {
        font-size: 14px !important;
    }
    .my-account-details {
        margin-bottom: 20px !important;
    }
    .dashboard-statistics-container:nth-child(even),
    .user-statistics-container {
        border-right: none !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 500px) {
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
}

@media screen and (min-width: 100px) and (max-width: 480px) {
    .amount-n-dropdown {
        display: flex;
        flex-direction: column;
    }
    .amount-n-dropdown button {
        margin-top: 10px;
    }
    .secret-container>span:last-of-type {
        left: 0px;
    }
    .width100 {
        width: 100%;
    }
    .my-account-error-msg {
        font-size: 14px !important;
    }
    .mobile-text-small {
        font-size: 12px !important;
    }
    .mobile-text-default {
        font-size: 14px !important;
    }
    .all-wrapper.with-pattern {
        min-height: 90vh !important;
    }
    .instructon-modal-container {
        width: 90%;
    }
    .permian-button {
        padding: 15px 48px;
    }
    .form-check {
        padding-left: 0px !important;
    }
    .width-100 {
        width: 100% !important;
    }
    .pl-none {
        padding-left: 0px !important;
    }
    .type-selector label {
        font-size: 13px;
    }
    .type-selector {
        padding: 10px !important;
    }
    .auth-box-w.wider,
    .auth-box-w.login-box {
        margin-top: 2em;
        border-radius: 0px !important;
    }
    .auth-box-w form,
    .auth-box-w.login-box form {
        padding: 20px 20px 40px 20px !important;
        width: 351px;
    }
    .auth-box-w {
        background-color: transparent !important;
    }
    .auth-box-w.signupwidth {
        margin-top: 2em;
        min-width: 50% !important;
    }
    iframe {
        width: 300px;
    }
    .auth-box-w .logo-w {
        padding: 20px 0 0 0 !important;
        width: 100%;
    }
    .terms-block,
    .info-bottom-link {
        font-size: 14px !important;
    }
    .main-content-container {
        margin-top: 0px !important;
    }
    .custom-form .input-group-text {
        top: 10% !important;
        padding: 10px !important;
    }
    .custom-form .form-control {
        padding: 12px !important;
    }
    .wrapper {
        margin-top: 10% !important;
    }
    .top-bar:not(.admin-top-bar) .top-menu-controls {
        padding: 10px 0 !important;
    }
    .split-radio-container label,
    .wealth-split-radio-container label,
    .understanding-split-radio-container label {
        min-width: 100% !important;
    }
    .main-content-container {
        padding-top: 0 !important;
    }
    .menu-container {
        background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 300%);
        background-repeat: repeat-x;
        color: rgba(255, 255, 255, 0.9);
    }
    .custom-top-bar {
        display: none !important;
    }
    .customLogo {
        margin-left: 0 !important;
    }
    .menu-mobile .mm-logo-buttons-w .mm-logo img {
        width: auto !important;
    }
    .buy-xpr-header-block {
        font-size: 14px !important;
    }
    /* .dashboard-statistics-container {
    margin: 10px 0 !important;
  } */
    .my-account-details {
        margin-bottom: 20px !important;
    }
    .onboarding-modal .onboarding-slide .onboarding-content {
        padding: 15px !important;
    }
    .my-account-container h5 span {
        font-weight: 0.8rem !important;
    }
    .my-account-container h5 span.pull-right {
        margin-top: 0.4rem !important;
    }
    .two-factor-block {
        text-align: center !important;
    }
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
    .two-factor-heading-header {
        margin-top: 40px !important;
    }
    .buy-xpr-input,
    .tokens-xpr-input {
        width: 65% !important;
        padding-left: 1px !important;
        font-size: 14px !important;
        padding-right: 1px !important;
    }
    .buy-xpr-currencey-select {
        width: 35% !important;
        height: 30px !important;
        border-radius: 0px 8px 8px 0px;
        background-color: #ffffff;
        border: 1px solid #ebebeb !important;
        padding: 0 2px !important;
        font-size: 16px;
    }
    .buy-xpr-block {
        padding: 0.8rem !important;
    }
    .buy-xpr-container {
        font-size: 12px !important;
    }
    .converter-sign {
        padding-left: 0.5rem !important;
    }
    .buy-xpr-label {
        padding-right: 0.5rem !important;
        margin-top: 0.3rem !important;
    }
    .token-symbol-input {
        width: 30% !important;
        padding-top: 3px !important;
    }
    .eth-contribution-input {
        width: 100% !important;
        margin-top: 10px !important;
    }
    .buy-xpr-button {
        padding: 5px 15px !important;
        margin-top: 10px !important;
        margin-left: 0 !important;
    }
    .buy-online-payment-buttons {
        padding: 5px 15px !important;
        margin-top: 10px !important;
        margin-left: 0 !important;
        width: auto !important;
    }
    .user-dashboard-content {
        min-height: 85vh !important;
    }
    .wallet-header {
        font-size: 14px !important;
    }
    .buy-xpr-container h6 {
        font-weight: 200;
    }
    .identification-header {
        font-size: 18px !important;
    }
    .kyc-container.mt-4 {
        margin-top: 15px !important;
    }
    .identification-block .permian-button {
        padding: 15px 45px !important;
    }
    .country-top-margin {
        margin-top: 15px !important;
    }
    .our-service-container .iradio_square-blue {
        margin-left: 0px !important;
    }
    .exchange-top-bar {
        padding: 0px !important;
        flex-direction: inherit !important;
    }
    .exchange-top-bar img {
        height: 55px !important;
    }
    .exchange-dashboard-content {
        padding-top: 70px !important;
    }
    .exchange-content-container {
        padding: 0% !important;
    }
    .sell-text,
    .buy-text {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    .buy-xpr-container h6.sell-text,
    .buy-xpr-container h6.buy-text {
        font-weight: 600 !important;
    }
    .exchange-currnecy {
        float: right !important;
    }
    .xpr-unit-display-container,
    .wallet-balance-display-container {
        padding-top: 15px;
    }
    .xpr-unit-display-text {
        text-align: left !important;
    }
    .market-other-details-container .pl-2 {
        padding-left: 0px !important;
    }
    .wallet-balance-display-text {
        float: inherit !important;
    }
    .wallet-balance-display-text .exchange-btn2 {
        float: right !important;
    }
    .permian-exchange-content-panel {
        height: auto !important;
        min-height: auto !important;
    }
    .border-right-seperator {
        border-right: none !important;
    }
    .exchange-input2 {
        width: 80% !important;
    }
    .create-sell-mandate-container {
        margin-top: 25px !important;
        border-top: 1px solid white !important;
        padding-top: 25px !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .center-content {
        padding-top: 0px !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .footer,
    .base-footer .footer {
        line-height: inherit !important;
        margin-top: 30px !important;
        width: auto;
        margin: auto;
    }
    .two-factor-block .notification-text {
        text-align: left !important;
    }
    .account-radio-container label {
        min-width: 25% !important;
    }
    .mm-buttons {
        pointer-events: auto !important;
        cursor: pointer !important;
    }
    .auth-box-w {
        box-shadow: none !important;
    }
    .signuppt {
        padding-top: 1.5rem !important;
    }
    .signup-button {
        padding: 10px !important;
    }
}

@media screen and (min-width: 778px) and (max-width: 1024px) {
    .menu-mobile .mm-logo-buttons-w .mm-logo img {
        width: auto !important;
    }
    .sb-avatar__image {
        width: 180px;
        height: 140px;
    }
    .sb-avatar__text {
        margin: auto;
    }
    .investor-emd-box .sb-avatar {
        width: 100% !important;
        text-align: center;
    }
    .post-box {
        height: 95% !important;
        display: block;
    }
    .post-box .post-content {
        /* -webkit-flex: 1 1; */
        flex: inherit;
        padding: 10px;
        padding-left: 20px;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .mm-logo-buttons-w {
        display: none !important;
    }
}

@media screen and (min-width: 180px) and (max-width: 961px) {
    .signuppage-info-bottom-link {
        width: 100%;
        text-align: center;
    }
    .signin-info-bottom-link {
        text-align: center !important;
    }
    .width-100 {
        width: 100% !important;
    }
    .btn-center-group {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .mm-logo-buttons-w {
        display: none !important;
    }
}

@media screen and (min-width: 500px) and (max-width: 575px) {
    .two-factor-heading-text {
        margin-top: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .my-account-details {
        margin-bottom: 20px !important;
    }
    .mgtp-md-4 {
        margin-top: 1.5rem;
    }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 645px) {
    .instructon-modal-container {
        width: 80%;
    }
}

@media screen and (min-device-width: 646px) and (max-device-width: 767px) {
    .instructon-modal-container {
        width: 70%;
    }
    .mm-logo-buttons-w {
        display: block !important;
    }
}

@media screen and (min-device-width: 480px) and (max-device-width: 767px) {
    .mm-logo-buttons-w {
        display: block !important;
    }
    .top-bar {
        display: none !important;
    }
    .center-content {
        padding-top: 0px !important;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 815px) {
    .filter-element-wrapper .form-control {
        width: 18em !important;
    }
    .instructon-modal-container {
        width: 65%;
    }
}

@media screen and (min-device-width: 816px) and (max-device-width: 991px) {
    .instructon-modal-container {
        width: 60%;
    }
}

@media screen and (max-width: 380px) {
    .permian-button {
        width: 90%;
    }
}


/* @media screen and (max-width: 480px) {
  .centerofthescreen {
    height: 88vh;
  }
} */

@media screen and (max-width: 575px) {
    .kyc-suitability {
        display: flex;
        flex-direction: column;
    }
    .btn-column {
        width: 80% !important;
    }
    .kyc-content {
        height: 50vh;
    }
}

@media screen and (max-width: 440px) {
    .react-confirm-alert-body {
        width: 95% !important;
        margin: auto !important;
    }
    .react-confirm-alert-body>h1 {
        font-size: 30px !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .portfolio-btn {
        display: flex !important;
        justify-content: center !important;
        width: 80% !important;
        margin: auto;
    }
}

@media screen and (min-width: 992px) and (max-width: 1162px) {
    .card-padding {
        height: 160px !important;
    }
}

@media screen and (max-width: 576px) {
    .portfolio-btn {
        display: block;
        margin: auto;
    }
    .icon-list,
    .os-icon {
        display: block !important;
    }
}

@media only screen and (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .content-box {
        min-height: 86vh !important;
    }
}

@media only screen and (min-device-width: 481px) {
    .layout-w {
        height: 100vh !important;
    }
}

.all-wrapper {
    max-width: 100% !important;
}